import {generateUniqueId} from '../utils/index.js';
import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchEditor extends HTMLElement {
    /* for form elements */
    static formAssociated = true;

    $internals;

    $fallbackmode = false;

    static get observedAttributes() {
        return [
            'language',
            'value',
            'name',
            'id',
            'disabled',
            'label',
            'mode',
            'activeMode',
        ];
    }

    constructor() {
        super();
        /* for form elements */
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
    }

    get variant() {
        return this.getAttribute('variant');
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }

    get mode() {
        const md = this.getAttribute('mode');
        if ((md === 'wysiwig' || md === 'both') && this.language !== 'html') {
            return 'syntax';
        }
        return md;
    }

    set mode(value) {
        this.setAttribute('mode', value);
    }

    get activeMode() {
        return this.getAttribute('activeMode') || 'wysiwyg';
    }

    set activeMode(value) {
        this.setAttribute('activeMode', value);
    }

    get id() {
        return this.getAttribute('id') || generateUniqueId();
    }

    set id(value) {
        this.setAttribute('id', value);
    }

    get language() {
        return this.getAttribute('language');
    }

    set language(value) {
        this.setAttribute('language', value);
    }

    get label() {
        return this.getAttribute('label');
    }

    set label(value) {
        this.setAttribute('label', value);
    }

    get value() {
        return this.getAttribute('value');
    }

    set value(value) {
        this.setAttribute('value', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.value);
        }
        this.querySelector('#btnSyntaxEditor')?.addEventListener(
            'sk-click',
            () => {
                const activeElement = this.querySelector('#wysiwyg');
                activeElement.classList.remove('active');
                this.querySelector(`#syntaxeditor`).classList.add('active');
                this.querySelector(`#btnwysiwyg`).classList.toggle('hidden');
                this.querySelector(`#btnSyntaxEditor`).classList.toggle(
                    'hidden'
                );
            }
        );
        this.querySelector('#btnwysiwyg')?.addEventListener('sk-click', () => {
            const activeElement = this.querySelector('#syntaxeditor');
            activeElement.classList.remove('active');
            this.querySelector(`#wysiwyg`).classList.add('active');
            this.querySelector(`#btnwysiwyg`).classList.toggle('hidden');
            this.querySelector(`#btnSyntaxEditor`).classList.toggle('hidden');
        });
        // move updated content from wysiwyg editor to syntax editor
        this.querySelector('sketch-htmlwysiwig')?.addEventListener(
            'sk-change',
            (event) => {
                this.querySelector('sketch-highlighteditor').setAttribute(
                    'value',
                    event.detail.value
                );
                if (!this.fallbackmode) {
                    this.$internals.setFormValue(event.detail.value);
                }
            }
        );
        // move updated content from syntax editor to wysiwyg editor
        this.querySelector('sketch-highlighteditor')?.addEventListener(
            'sk-change',
            (event) => {
                this.querySelector('sketch-htmlwysiwig').setAttribute(
                    'value',
                    event.detail.value
                );
                if (!this.fallbackmode) {
                    this.$internals.setFormValue(event.detail.value);
                }
            }
        );
    }

    _isWysiwygEditorActive = () => {
        if (this.language === 'html') {
            return (
                this.mode === 'wysiwyg' ||
                (this.mode === 'both' && this.activeMode === 'wysiwyg')
            );
        }
        return false;
    };

    render() {
        this.innerHTML = `
        <style>
        .wrapper > .syntaxeditor,
        .wrapper > .wysiwyg {
            display: none
        }
        .wrapper .active {
            display: block;
        }
        label {
            display: block;
            margin-bottom: var(--sketchSpacing4);
        }
        .buttonrow {
            display: flex;
            justify-content: flex-end;
            margin-bottom: var(--sketchSpacing4);
        }
        sketch-button.hidden {
            display: none;
        }
        </style>
        ${
            this.mode === 'both'
                ? `
                <div class="buttonrow">
                    <sketch-button variant="default" id="btnSyntaxEditor" size="sm" icon="embed2" ${this._isWysiwygEditorActive() ? '' : 'class="hidden"'}></sketch-button>
                    <sketch-button variant="default" ${this._isWysiwygEditorActive() ? 'class="hidden"' : ''} id="btnwysiwyg" size="sm" icon="paragraph-justify"></sketch-button>
                </div>`
                : ''
        }
        
        <div class="wrapper">
        ${
            this.label
                ? `<label for="${this.id}">
            <sketch-text variant="footnote">
                ${this.label}
            </sketch-text>
        </label>`
                : ''
        }
        ${
            this.mode === 'both' || this.mode === 'syntax'
                ? `
                <div id="syntaxeditor" class="syntaxeditor ${this._isWysiwygEditorActive() ? '' : 'active'}">
                    <sketch-highlighteditor value="${this.value}"></sketch-highlighteditor>
                </div>`
                : ''
        }
        ${
            this.mode === 'both' || this.mode === 'wysiwyg'
                ? `<div id="wysiwyg" class="wysiwyg ${this._isWysiwygEditorActive() ? 'active' : ''}">
            <sketch-htmlwysiwig id="${this.id}" value="${this.value}"></sketch-htmlwysiwig>
        </div>`
                : ''
        }        
            
        </div>`;
    }
}
